import axios from 'axios';
import { getToken } from '../services/auth.service';
import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';

const ACCEPT_HEADER = {
  Accept: 'application/json, text/plain, */*, application/vnd.innoctopus.1.1+json',
};

/*
const RENDERING_HEADERS = {
  'X-Application-Renderer': 'draft',
};*/

const getLoader = () => {
  return O.fromNullable(document.getElementById('loader'));
};

const startLoader = () => {
  pipe(
    getLoader(),
    O.map(loader => loader.classList.add('loading')),
  );
};

const stopLoader = () => {
  pipe(
    getLoader(),
    O.map(loader => loader.classList.remove('loading')),
  );
};

const getBearerHeader = () => {
  return pipe(
    getToken()(),
    O.fold(() => ({}), token => ({ Bearer: token })),
  );
};

const configureInterceptors = () => {
  axios.interceptors.request.use(config => {
    startLoader();

    return {
      ...config,
      headers: {
        ...config.headers,
        ...ACCEPT_HEADER,
        //...RENDERING_HEADERS,
        ...getBearerHeader(),
      },
    };
  });

  axios.interceptors.response.use(response => {
    stopLoader();

    return response;
  });
};

const configureAxios = () => {
  configureInterceptors();
};

export default configureAxios;
