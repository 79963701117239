import React from 'react';
import { Link as LinkType, WithBlockProps } from '../../types/innoctopus/flow';

import * as Styled from './Link.styles';

export const Link = (props: WithBlockProps<LinkType>): JSX.Element => {
  const { url, content, external } = props.block;

  const aProps = {
    href: url,
    ...(external
      ? {
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      : {}),
  };

  return (
    <Styled.Container>
      <Styled.Arrow>
        <span />
        <span />
        <span />
      </Styled.Arrow>
      <Styled.Link {...aProps}>{content}</Styled.Link>
    </Styled.Container>
  );
};
