import React from 'react';
import { Title as TitleType, WithBlockProps } from '../../types/innoctopus/flow';

import { Title as TitleStyled } from '../../styles/shared';

export const Title = (props: WithBlockProps<TitleType>): JSX.Element => {
  const { level, content } = props.block;

  return <TitleStyled level={level}>{content}</TitleStyled>;
};
