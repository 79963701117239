import React, { Fragment } from 'react';

import { resolveTemplate } from '../helpers/innoctopus/template.resolver';

import { Block } from '../types/innoctopus/flow';

interface FlowProps {
  blocks: ReadonlyArray<Block>;
}

const renderBlock = (block: Block, i: number): JSX.Element => {
  const Template = resolveTemplate(block.template);
  return <Template key={i} block={block} />;
};

const Flow = ({ blocks }: FlowProps) => <Fragment>{blocks.map(renderBlock)}</Fragment>;

export default Flow;
