import React, { SyntheticEvent } from 'react';

import placeholder from '../../../assets/images/placeholder/placeholder.png';

import * as O from 'fp-ts/lib/Option';
import { Resource, ResourceUrls } from '../../../types/innoctopus';
import { pipe } from 'fp-ts/lib/pipeable';

interface ImageOrPlaceholderProps {
  src?: string;
  resource?: Resource;
  size?: Extract<keyof ResourceUrls, string>;
  alt?: string;
  onClick?: () => void;
}

const getSize = (size?: Extract<keyof ResourceUrls, string>): Extract<keyof ResourceUrls, string> => {
  return pipe(
    O.fromNullable(size),
    O.getOrElse(() => 'xxhdpi_3x' as Extract<keyof ResourceUrls, string>),
  );
};

const rewriteHttpToHttps = (url: string): string => url.replace('http://', 'https://');

const onError = (e: SyntheticEvent<HTMLImageElement>) => {
  const target = e.target as HTMLImageElement;
  target.onerror = null;
  target.src = placeholder;
};

export const ImageOrPlaceholder = ({ src, resource, size, alt, onClick }: ImageOrPlaceholderProps) => {
  const url = pipe(
    O.fromNullable(src),
    O.alt(() =>
      pipe(
        O.fromNullable(resource),
        O.mapNullable(value => value.url[getSize(size)]),
        O.map(rewriteHttpToHttps),
      ),
    ),
    O.getOrElse(() => placeholder),
  );

  const imgProps = {
    src: url,
    onError,
    ...(onClick ? { onClick } : {}),
  };

  const alternative = pipe(
    O.fromNullable(alt),
    O.alt(() =>
      pipe(
        O.fromNullable(resource),
        O.map(value => value.name),
      ),
    ),
    O.toUndefined,
  );

  return <img alt={alternative} {...imgProps} />;
};
