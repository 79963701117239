import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-bottom: 40px;
`;

export const Glide = styled.div`
  position: relative;
  padding: 0 40px;
`;

export const Slide = styled.div`
  position: relative;
  padding-bottom: 55%;
  cursor: grab;

  img {
    position: absolute;
    top: 50%;
    left: 0;
    min-height: calc(100% - 2px);
    min-width: 100%;
    max-width: 100%;
    object-fit: cover;
    transform: translateY(-50%);
  }
`;

const Arrow = css`
  position: absolute;
  margin: 0;
  padding: 0;
  top: calc(50% - 20px);
  width: 20px;
  height: 40px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  &::before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    width: 28px;
    background: ${props => props.theme.colors.primary[500]};
  }

  &::before {
    transform: rotate(45deg);
    top: 20px;
  }

  &::after {
    transform: rotate(-45deg);
    bottom: 18px;
  }
`;

export const LeftArrow = styled.button`
  ${Arrow};
  left: 0;

  &::before,
  &::after {
    left: 0;
    transform-origin: left;
  }
`;

export const RightArrow = styled.button`
  ${Arrow};
  right: 0;

  &::before,
  &::after {
    right: 0;
    transform-origin: right;
  }
`;
