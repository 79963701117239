import styled from 'styled-components';

export const LoaderContainer = styled.div<{ white?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: ${props =>
    props.white ? props.theme.colors.background.surface : props.theme.colors.background.extraLight};
  flex: 1 1 auto;
`;

export const LoaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-top: 20px;
  }
`;
