import React, { Component, Suspense } from 'react';

import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme.styles';
import { GlobalStyle } from './styles/global.styles';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { routes } from './routes';

import { LoadingPage } from './components/shared/loading-page/LoadingPage';

const RouteNotFound = () => <span>404 not found</span>;

class App extends Component {
  private renderRoutes = () => {
    return routes.map(({ path, component: Component }, i) => (
      <Route exact key={i} path={path}>
        <Component />
      </Route>
    ));
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router>
          <GlobalStyle />
          <Suspense fallback={<LoadingPage />}>
            <Switch>
              {this.renderRoutes()}
              <Route component={RouteNotFound} />
            </Switch>
          </Suspense>
        </Router>
      </ThemeProvider>
    );
  }
}

export default App;
