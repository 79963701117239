import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components';

import { renderTypographyStyle } from '../utils.styles';

export const Form = styled.form`
  width: 100%;
`;

interface fieldProps {
  light?: boolean;
  error?: boolean;
}

const getColor = ({ theme, light, error }: ThemedStyledProps<fieldProps, DefaultTheme>): string =>
  error ? theme.colors.error : light ? theme.colors.text.light : theme.colors.text.dark;

export const Field = styled.div<fieldProps>`
  position: relative;
  width: 100%;
  margin: 35px 0;

  label {
    position: absolute;
    top: 5px;
    left: 0;
    display: block;
    width: 100%;
    ${props => renderTypographyStyle(props.theme.typography.text.small)};
    color: ${props => getColor(props)};
    opacity: 0.6;
    transform: translateY(0px);
    transition: transform 0.15s;
    cursor: pointer;
  }

  input {
    width: 100%;
    height: 30px;
    background: none;
    border: none;
    border-bottom: 1px solid ${props => getColor(props)};
    ${props => renderTypographyStyle(props.theme.typography.text.standard)};
    color: ${props => getColor(props)};
    margin-bottom: 1px;
    outline: none;
    box-sizing: border-box;
    border-radius: 0;

    &:focus {
      border-bottom: 1px solid ${props => getColor(props)};

      & + label {
        transform: translateY(-25px);
      }

      margin-bottom: 0;
    }

    &:not([value='']) + label {
      transform: translateY(-25px);
    }
  }
`;

export const FormAction = styled.div`
  text-align: center;
`;
