import React, { ReactNode } from 'react';

import styled, { Titles } from 'styled-components';

import { renderTypographyStyle } from '../utils.styles';

const TitleStyled = styled.h1<{ level: Extract<keyof Titles, string> }>`
  width: 100%;
  ${props => renderTypographyStyle(props.theme.typography.titles[props.level])};
  margin-bottom: 40px;
`;

interface titleProps {
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  level?: Extract<keyof Titles, string>;
  children?: ReactNode;
}

export const Title = (props: titleProps) => {
  const level = props.level || 'h1';
  const as = props.as || (level === 'titlePage' ? 'p' : level);

  return (
    <TitleStyled as={as} level={level}>
      {props.children}
    </TitleStyled>
  );
};
