import React from 'react';

import { WithBlockProps, Youtube as YoutubeType } from '../../types/innoctopus/flow';

import * as Styled from './Youtube.styles';

export const Youtube = ({ block }: WithBlockProps<YoutubeType>): JSX.Element => (
  <Styled.Container>
    <Styled.Content>
      <Styled.Iframe
        src={`https://www.youtube.com/embed/${block.youtubeId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Styled.Content>
  </Styled.Container>
);
