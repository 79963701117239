import { Block } from './flow';

export enum ContactType {
  PHONE = 'phone_contact',
  MAIL = 'mail_contact',
}

export interface Contact extends Block {
  readonly type: ContactType;
  readonly target: string;
  readonly text: string;
  readonly richText: string;
}
