import React, { FC } from 'react';
import { Contact as ContactFlowType, ContactType, WithBlockProps } from '../../types/innoctopus/flow';

import * as Styled from './Contact.styles';
import { Title } from '../../styles/shared';

const ContactLink: FC<WithBlockProps<ContactFlowType>> = ({ block }) => {
  switch (block.type) {
    case ContactType.PHONE:
      return (
        <Styled.Contact>
          <a href={`tel:${block.target}`}>{block.target}</a>
        </Styled.Contact>
      );
    case ContactType.MAIL:
      return (
        <Styled.Contact>
          <a href={`mailto:${block.target}`}>{block.target}</a>
        </Styled.Contact>
      );
    default:
      return <Styled.Contact>{block.target}</Styled.Contact>;
  }
};

export const Contact = (props: WithBlockProps<ContactFlowType>): JSX.Element => {
  const { block } = props;

  return (
    <Styled.Container>
      <Styled.Title>
        <Title level="h4">{block.text}</Title>
      </Styled.Title>
      <Styled.Content>
        <ContactLink block={block} />
        {block.richText && <Styled.RichText dangerouslySetInnerHTML={{ __html: block.richText }} />}
      </Styled.Content>
    </Styled.Container>
  );
};
