import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';

export const getConfig = (): { [key: string]: string | undefined } => {
  return {
    ...process.env,
    ...window._env_,
  };
};

export const getConfigValue = (key: string) => {
  return O.fromNullable(getConfig()[key]);
};

export const isProduction = () => {
  return pipe(
    getConfigValue('env'),
    O.fold(() => false, value => value === 'production'),
  );
};

export const isDevelopment = () => {
  return !isProduction();
};
