import React, { createContext, ComponentType, FC } from 'react';

import { Option } from 'fp-ts/lib/Option';

import { Page } from './types/innoctopus';

import { SubNavItem } from './components/layouts/header/nav/sub-nav/SubNav';

const ctx = createContext<Page<any> | null>(null);

export const InnoctopusContextProvider = ctx.Provider;

export const InnoctopusContextConsumer = ctx.Consumer;

export interface WithInnoctopusContext<T> {
  readonly page: Page<T>;
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export const withInnoctopusContext = <P extends WithInnoctopusContext<any>, R = Omit<P, 'page'>>(
  Component: ComponentType<P>,
): FC<R> => (props: R) => (
  <InnoctopusContextConsumer>{value => <Component {...props as any} page={value} />}</InnoctopusContextConsumer>
);

export interface SubNavItemsProps {
  activeSubNavItem: Option<number>;
  setSubNavItems: (items: Array<SubNavItem>) => void;
  resetSubNavItems: () => void;
  setActiveSubNavItem: (index: number) => void;
}
