import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { HttpError, HttpTask } from '../models/http.model';

import * as TE from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/pipeable';

const onError = <E = unknown>(err: unknown): HttpError<E> => {
  return HttpError.fromAxiosError<E>(err as AxiosError);
};

const transformRequest = <R, E>(request: () => Promise<AxiosResponse<R>>): HttpTask<R, E> => {
  return pipe(
    TE.tryCatch(request, err => onError<E>(err)),
    TE.map(res => res.data),
  );
};

export const get = <R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E> => {
  return transformRequest<R, E>(() => axios.get<R>(url, config));
};

export const post = <R = unknown, E = unknown>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): HttpTask<R, E> => {
  return transformRequest<R, E>(() => axios.post<R>(url, data, config));
};

export const put = <R = unknown, E = unknown>(url: string, data?: any, config?: AxiosRequestConfig): HttpTask<R, E> => {
  return transformRequest<R, E>(() => axios.put<R>(url, data, config));
};

export const del = <R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E> => {
  return transformRequest<R, E>(() => axios.delete(url, config));
};
