import styled from 'styled-components';

import { Text } from '../../styles/shared';
import { mediaMax } from '../../styles/utils.styles';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;

  > div {
    &:first-child {
      margin: 10px 12px 0 0;
    }

    &:last-child {
      margin: 10px 0 0 12px;
    }

    ${mediaMax.content`
    &:last-child, &:first-child  {
      margin: 10px 0 0;
    `}
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 100%;
  padding: 40px 35px 50px;
  background: ${props => props.theme.colors.background.surface};
  box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
`;

export const RichText = styled(Text)`
  opacity: 0.6;
`;

export const Pin = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
  text-align: center;

  span {
    display: inline-block;
    position: relative;
    width: 75px;
    height: 75px;
    border-radius: 50% 50% 50% 0;
    border: 2px solid ${props => props.theme.colors.primary[300]};
    transform: rotate(-45deg);

    &:after {
      content: '';
      position: absolute;
      top: 19px;
      left: 19px;
      width: 30px;
      height: 30px;
      border: 2px solid ${props => props.theme.colors.primary[300]};
      border-radius: 50%;
    }
  }
`;

export const Map = styled.div`
  flex: 1 1 50%;
  min-height: 440px;
  background: #f6f6f6;
`;
