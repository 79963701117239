import { LazyExoticComponent, lazy } from 'react';

interface Route {
  path: string;
  component: LazyExoticComponent<any>;
}

export const routes: ReadonlyArray<Route> = [
  {
    path: '/login',
    component: lazy(() => import('./containers/login/Login')),
  },
  {
    path: '/password',
    component: lazy(() => import('./containers/password/ResetPassword')),
  },
  {
    path: '/:uri?',
    component: lazy(() => import('./containers/page/Page')),
  },
];
