import { DefaultTheme, Colors, BackgroundColors, TextColors, Font, Fonts } from 'styled-components';

const primary: Colors = {
  100: '#f7ecde',
  200: '#efd9bd',
  300: '#e6c69c',
  400: '#deb37b',
  500: '#d6a05a',
  600: '#cc8b50',
  700: '#9e6c3b',
  800: '#70411b',
  900: '#351f09',
};

const secondary: Colors = {
  100: '#eed3d3',
  200: '#dda7a7',
  300: '#cc7a7c',
  400: '#bb4e50',
  500: '#aa2224',
  600: '#8e1e24',
  700: '#721922',
  800: '#56131d',
  900: '#3a0d16',
};

const tiertiary: Colors = {
  100: '#eae9ea',
  200: '#cac9ca',
  300: '#aaa9ab',
  400: '#959395',
  500: '#6b686b',
  600: '#403d41',
  700: '#2b282c',
  800: '#1b1a1c',
  900: '#000000',
};

const background: BackgroundColors = {
  light: '#f5f4f2',
  extraLight: '#f9fafb',
  dark: tiertiary[600],
  surface: '#ffffff',
  hightlight: secondary[200],
};

const text: TextColors = {
  dark: '#000000',
  light: background.surface,
};

const primaryFont: Font = {
  family: `'Open Sans', sans-serif`,
};

const secondaryFont: Font = {
  family: `'Rubik', sans-serif`,
};

const fonts: Fonts = {
  primary: primaryFont,
  secondary: secondaryFont,
  weights: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
};

export const theme: DefaultTheme = {
  colors: {
    primary,
    secondary,
    tiertiary,
    background,
    text,
    error: '#aa2224',
  },
  fonts,
  typography: {
    titles: {
      h1: {
        font: secondaryFont,
        weight: fonts.weights.medium,
        size: {
          mobile: 40,
          desktop: 45,
        },
        lineHeight: 1.33,
      },
      h2: {
        font: secondaryFont,
        weight: fonts.weights.medium,
        size: {
          mobile: 30,
          desktop: 35,
        },
        lineHeight: 1.37,
      },
      h3: {
        font: secondaryFont,
        weight: fonts.weights.medium,
        size: {
          mobile: 24,
          desktop: 28,
        },
        lineHeight: 1.43,
      },
      h4: {
        font: secondaryFont,
        weight: fonts.weights.medium,
        size: {
          mobile: 22,
          desktop: 24,
        },
        lineHeight: 1.43,
        textTransform: 'uppercase',
      },
      h5: {
        weight: fonts.weights.bold,
        size: {
          mobile: 18,
          desktop: 20,
        },
        lineHeight: 1.5,
      },
      h6: {
        weight: fonts.weights.semiBold,
        size: {
          mobile: 15,
          desktop: 16,
        },
        lineHeight: 1.88,
      },
      titlePage: {
        font: secondaryFont,
        weight: fonts.weights.light,
        size: {
          mobile: 28,
          desktop: 30,
        },
        lineHeight: 1.33,
      },
    },
    text: {
      large: {
        weight: fonts.weights.semiBold,
        size: {
          mobile: 17,
          desktop: 19,
        },
        lineHeight: 1.67,
      },
      standard: {
        size: {
          mobile: 16,
          desktop: 17,
        },
        lineHeight: 1.76,
      },
      medium: {
        size: {
          mobile: 15,
          desktop: 16,
        },
        lineHeight: 1.88,
      },
      small: {
        size: {
          mobile: 13,
          desktop: 14,
        },
        lineHeight: 1.71,
      },
    },
  },
  buttons: {
    types: {
      primary: {
        background: primary[500],
        color: background.surface,
        weight: fonts.weights.semiBold,
        size: {
          mobile: 15,
          desktop: 16,
        },
        textTransform: 'uppercase',
        hover: {
          background: primary[700],
        },
        disabled: {
          opacity: 0.4,
        },
      },
      secondary: {
        background: background.surface,
        color: primary[500],
        weight: fonts.weights.semiBold,
        size: {
          mobile: 14,
          desktop: 15,
        },
        textTransform: 'uppercase',
        border: {
          color: primary[500],
        },
        hover: {
          color: primary[700],
          size: {
            mobile: 14,
            desktop: 15,
          },
          border: {
            color: primary[700],
          },
        },
        disabled: {
          opacity: 0.4,
          border: {
            color: primary[700],
          },
        },
      },
    },
    sizes: {
      small: 35,
      medium: 45,
      large: 55,
    },
  },
  breakpoints: {
    content: 1370,
    desktop: 960,
    tablet: 760,
    mobile: 560,
  },
};
