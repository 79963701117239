import styled, { Text as TextType } from 'styled-components';

import { renderTypographyStyle } from '../utils.styles';

interface textProps {
  size?: Extract<keyof TextType, string>;
  light?: boolean;
  align?: 'center' | 'right' | 'left';
}

export const Text = styled.p<textProps>`
  ${props => renderTypographyStyle(props.theme.typography.text[props.size || 'standard'])}
  color: ${props => props.light && props.theme.colors.text.light};
  text-align: ${props => props.align};
  margin-bottom: 30px;
`;
