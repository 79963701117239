import { css, DefaultTheme, ThemedCssFunction, FontSize, Typography, Border } from 'styled-components';

import { theme } from './theme.styles';

export const mediaMin = (Object.keys(theme.breakpoints) as (keyof typeof theme.breakpoints)[]).reduce(
  (acc, label) => {
    acc[label] = (first: any, ...interpolations: any[]) => css`
      @media (min-width: ${theme.breakpoints[label]}px) {
        ${css(first, ...interpolations)}
      }
    `;

    return acc;
  },
  {} as { [key in keyof typeof theme.breakpoints]: ThemedCssFunction<DefaultTheme> },
);

export const mediaMax = (Object.keys(theme.breakpoints) as (keyof typeof theme.breakpoints)[]).reduce(
  (acc, label) => {
    acc[label] = (first: any, ...interpolations: any[]) => css`
      @media (max-width: ${theme.breakpoints[label]}px) {
        ${css(first, ...interpolations)}
      }
    `;

    return acc;
  },
  {} as { [key in keyof typeof theme.breakpoints]: ThemedCssFunction<DefaultTheme> },
);

export const fontSize = (size: FontSize | undefined) => {
  return (
    size &&
    css`
      font-size: ${size.mobile}px;

      ${size.desktop &&
        mediaMin.mobile`
          font-size: ${size.desktop}px;
        `};
    `
  );
};

export const renderTypographyStyle = (typography: Typography) => {
  return css`
    ${fontSize(typography.size)};
    ${typography.font && `font-family: ${typography.font.family}`};
    font-weight: ${typography.weight};
    letter-spacing: ${typography.letterSpacing};
    color: ${typography.color};
    line-height: ${typography.lineHeight};
    text-transform: ${typography.textTransform};
    text-decoration: ${typography.textDecoration ? typography.textDecoration : 'none'};
    opacity: ${typography.opacity};
  `;
};

export const renderBorder = (border?: Border) => {
  if (border) {
    const { color, radius } = border;

    const size = border.size ? `${border.size}px` : '1px';

    return css`
      border: ${color ? `${size} solid ${color}` : 'none'};
      ${radius && `border-radius: ${radius}px`};
    `;
  } else {
    return css`
      border: none;
    `;
  }
};

export const renderRatio = (ratio: number) => {
  return css`
    position: relative;
    padding-bottom: ${100 / (ratio && ratio > 0 ? ratio : 1)}%;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      min-height: 100%;
      min-width: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  `;
};
