export enum Template {
  // News
  NEWS_LIST = 'innoctopus:1.1:news.simple',
  NEWS_DETAIL = 'mouton-cadet:1.1:news-detail',

  // Nav
  NAV_SIMPLE = 'innoctopus:1.1:nav.simple',

  MY_SPACE = 'mouton-cadet:1.0:myspace',

  // Files
  FILE = 'innoctopus:1.1:file',
  PDF = 'innoctopus:1.1:pdf',
  GALLERY = 'innoctopus:1.1:gallery',

  // Youtube
  YOUTUBE = 'innoctopus:1.1:youtube',

  // Flow
  FLOW_SIMPLE = 'innoctopus:1.1:flow.simple',
  FLOW_ADDRESS = 'innoctopus:1.1:flow-address',
  FLOW_CONTACT = 'innoctopus:1.1:flow-contact',
  FLOW_GALLERY = 'innoctopus:1.1:flow-gallery',
  FLOW_IMAGE = 'innoctopus:1.1:flow-image',
  FLOW_LINK = 'innoctopus:1.1:flow-link',
  FLOW_TEXT = 'innoctopus:1.1:flow-text',
  FLOW_TITLE = 'innoctopus:1.1:flow-title',
  FLOW_YOUTUBE = 'innoctopus:1.1:flow-youtube',
}
