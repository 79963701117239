import { ComponentClass, FunctionComponent, lazy } from 'react';

import { Template } from '../../types/innoctopus';

import { Address, Contact, Image, Link, Text, Title, Youtube, Gallery } from '../../flow';

import TemplateNotFound from '../../components/errors/TemplateNotFound';
import * as O from 'fp-ts/lib/Option';
import { isDevelopment } from '../env.helper';
import { pipe } from 'fp-ts/lib/pipeable';

const registry: { [key in Template]?: any } = {
  // News
  [Template.NEWS_LIST]: lazy(() => import('../../components/news/News')),
  [Template.NEWS_DETAIL]: lazy(() => import('../../components/news/detail/NewsDetail')),

  // Nav
  [Template.NAV_SIMPLE]: lazy(() => import('../../components/nav/simple/NavSimple')),

  [Template.MY_SPACE]: lazy(() => import('../../components/my-space/MySpace')),

  // Files
  [Template.FILE]: lazy(() => import('../../components/file/File')),
  [Template.PDF]: lazy(() => import('../../components/pdf/Pdf')),
  [Template.GALLERY]: lazy(() => import('../../components/gallery/Gallery')),

  // Youtube
  [Template.YOUTUBE]: lazy(() => import('../../components/youtube/Youtube')),

  // Flow page Innoctopus
  [Template.FLOW_SIMPLE]: lazy(() => import('../../components/flow/FlowSimple')),

  //Flow
  [Template.FLOW_ADDRESS]: Address,
  [Template.FLOW_CONTACT]: Contact,
  [Template.FLOW_GALLERY]: Gallery,
  [Template.FLOW_IMAGE]: Image,
  [Template.FLOW_LINK]: Link,
  [Template.FLOW_TEXT]: Text,
  [Template.FLOW_TITLE]: Title,
  [Template.FLOW_YOUTUBE]: Youtube,
};

export const resolveTemplate = (template: Template): ComponentClass<any> | FunctionComponent<any> => {
  return pipe(
    O.fromNullable(registry[template]),
    O.getOrElse(() => {
      console.warn(`Template ${template} not found in registry`);
      return TemplateNotFound;
    }),
  );
};

export const logModules = () => {
  if (isDevelopment()) {
    console.info(`${Object.keys(registry).length} templates are ready in Innoctopus App ! \n`);
    console.info(`${Object.keys(registry).reduce((acc, curr) => `${acc} \n ${curr}`, '')}
    `);
  }
};
