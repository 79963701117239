import React from 'react';

import Loader from '../loader/Loader';

import { Text } from '../../../styles/shared';

import * as Styled from './LoadingPage.styles';

interface LoadingPageProps {
  text?: string;
  white?: boolean;
}

export const LoadingPage = ({ text, white }: LoadingPageProps) => (
  <Styled.LoaderContainer white={white}>
    <Styled.LoaderContent>
      <Loader />
      <Text>{text ? text : 'Chargement en cours...'}</Text>
    </Styled.LoaderContent>
  </Styled.LoaderContainer>
);
