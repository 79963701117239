import styled from 'styled-components';

import { rgba } from 'polished';

import { mediaMax } from '../../styles/utils.styles';

export const Container = styled.div`
  text-align: center;
  margin: 10px 0;
`;

export const Title = styled.div`
  display: inline-block;
  margin: 0 auto;
  padding: 15px 40px;
  background: ${props => props.theme.colors.primary[200]};
  transform: translateY(20px);

  > h4 {
    margin: 0;
  }
`;

export const Content = styled.div`
  padding: 65px;
  background: ${props => rgba(props.theme.colors.primary[100], 0.5) as any};
  text-align: left;
  border-radius: 4px;

  ${mediaMax.tablet`
    padding: 50px 30px 30px;
`}
`;

export const Contact = styled.p`
  font-weight: ${props => props.theme.fonts.weights.semiBold};

  &:before {
    content: '';
    display: inline-block;
    width: 100px;
    height: 3px;
    margin-right: 25px;
    background: ${props => props.theme.colors.primary[300]};
  }

  a {
    color: ${props => props.theme.colors.text.dark};
    text-decoration: none;
    cursor: pointer;
  }

  ${mediaMax.tablet`
    margin-right: 0;

    &:before {
      display: none;
    }

    &:after {
      content: '';
      display: block;
      width: 100px;
      height: 3px;
      margin-top: 20px;
      background: ${props => props.theme.colors.primary[300]};
    }
  `};
`;

export const RichText = styled.p`
  margin: 20px 0 0 125px;

  ${mediaMax.tablet`
    margin: 20px 0 0;
  `};
`;
