import styled, {
  css,
  FlattenSimpleInterpolation,
  ButtonTypes,
  ButtonSizes,
  Button as ButtonType,
} from 'styled-components';

import { mediaMin, renderTypographyStyle, renderBorder } from '../utils.styles';

export const calcSize = (button: ButtonType, size: number): FlattenSimpleInterpolation => {
  const fontSizeMobile = button.size ? button.size.mobile : 16;
  const fontSizeDesktop = button.size
    ? button.size.desktop
      ? button.size.desktop
      : button.size.mobile
    : fontSizeMobile;

  const lineHeightMobile = fontSizeMobile + 4;
  const lineHeightDesktop = fontSizeDesktop + 4;

  const paddingMobile = `${(size - lineHeightMobile) / 2}px ${size}px`;
  const paddingDesktop = `${(size - lineHeightDesktop) / 2}px ${size}px`;

  return css`
    padding: ${paddingMobile};
    line-height: ${lineHeightMobile}px;

    ${mediaMin.mobile`
        padding: ${paddingDesktop};
        line-height: ${lineHeightDesktop}px;
    `};
  `;
};

export const renderButtonStyle = (button: ButtonType, size: number): FlattenSimpleInterpolation => {
  return css`
    display: inline-block;
    ${renderTypographyStyle(button)};
    background: ${button.background};
    ${renderBorder(button.border)};
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    ${calcSize(button, size)}
    white-space: normal;
    max-width: 100%;
    text-align: center;

    &:hover {
      ${button.hover && renderButtonStyle(button.hover, size)};
    }

    &:disabled {
      ${button.disabled && renderButtonStyle(button.disabled, size)};
      cursor: not-allowed;
    }
  `;
};

interface buttonProps {
  buttonType?: Extract<keyof ButtonTypes, string>;
  buttonSize?: Extract<keyof ButtonSizes, string>;
}

export const Button = styled.button<buttonProps>`
  ${props =>
    renderButtonStyle(
      props.theme.buttons.types[props.buttonType || 'primary'],
      props.theme.buttons.sizes[props.buttonSize || 'medium'],
    )};
`;

export const LinkButton = styled.a<buttonProps>`
  ${props =>
    renderButtonStyle(
      props.theme.buttons.types[props.buttonType || 'primary'],
      props.theme.buttons.sizes[props.buttonSize || 'medium'],
    )};
`;

export const SubmitButton = styled.input<buttonProps>`
  ${props =>
    renderButtonStyle(
      props.theme.buttons.types[props.buttonType || 'primary'],
      props.theme.buttons.sizes[props.buttonSize || 'medium'],
    )};
`;
