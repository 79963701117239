import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 40px;
  text-align: center;

  img {
    max-width: 100%;
    cursor: pointer;
  }
`;
