import * as O from 'fp-ts/lib/Option';
import * as IO from 'fp-ts/lib/IO';
import * as TE from 'fp-ts/lib/TaskEither';

import { Credentials, LoginSuccessResponse, ResetPasswordRequest } from '../types/auth.model';
import { User } from '../types/innoctopus';
import { pipe } from 'fp-ts/lib/pipeable';
import { HttpTask } from '../models/http.model';
import { post } from './http.service';
import { constTrue } from 'fp-ts/lib/function';

const LOCAL_STORAGE_TOKEN_KEY = 'token';

export const getToken = (): IO.IO<O.Option<string>> => {
  return () => O.fromNullable(localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY));
};

const setToken = (token: string): IO.IO<void> => {
  return () => localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
};

export const login = (credentials: Credentials): HttpTask<boolean> => {
  return pipe(
    post<LoginSuccessResponse>('/api/v1/authenticate', credentials),
    TE.chain(({ token }) => TE.rightIO(setToken(token))),
    TE.map(constTrue),
  );
};

export const logout = (): IO.IO<void> => {
  return () => localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
};

export const resetPassword = (request: ResetPasswordRequest): HttpTask<boolean> => {
  return pipe(
    post('/api/v1/password/reset/request', request),
    TE.map(constTrue),
  );
};

const decodeToken = (token: string): User => {
  const payload = JSON.parse(atob(token.split('.')[1]));

  return {
    email: payload.email,
    firstName: payload.first_name,
    lastName: payload.last_name,
  };
};

export const getUser = (): IO.IO<O.Option<User>> => {
  return pipe(
    getToken(),
    IO.map(token =>
      pipe(
        token,
        O.map(decodeToken),
      ),
    ),
  );
};
