import React from 'react';
import { Text as TextType, WithBlockProps } from '../../types/innoctopus/flow';

import * as Styled from './Text.styles';

export const Text = (props: WithBlockProps<TextType>): JSX.Element => {
  const { content } = props.block;

  return <Styled.Container dangerouslySetInnerHTML={{ __html: content }} />;
};
