import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  padding-left: 80px;
  margin-bottom: 25px;
`;

export const Arrow = styled.div`
  display: inline-block;
  position: relative;
  width: 40px;
  height: 20px;

  span {
    display: block;
    position: absolute;
    height: 2px;
    background: ${props => props.theme.colors.primary[500]};

    &:first-child,
    &:last-child {
      right: 0;
      width: 10px;
    }

    &:first-child {
      transform: rotate(45deg);
      top: 6px;
    }

    &:last-child {
      transform: rotate(-45deg);
      bottom: 4px;
    }

    &:nth-child(2) {
      top: 10px;
      width: 100%;
    }
  }
`;

export const Link = styled.a`
  display: inline-block;
  margin-left: 15px;
  color: ${props => props.theme.colors.tiertiary[900]};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
`;
