import React from 'react';

import * as Styled from './Loader.styles';

const Loader = () => (
  <Styled.Loader>
    <div />
    <div />
    <div />
  </Styled.Loader>
);

export default Loader;
