import React, { Component } from 'react';

import GoogleMapReact from 'google-map-react';

import { WithBlockProps, Address as AddressType } from '../../types/innoctopus/flow';

import * as Styled from './Adress.styles';
import { Title, Text } from '../../styles/shared';
import { getConfigValue } from '../../helpers/env.helper';
import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';

export class Address extends Component<WithBlockProps<AddressType>> {
  private renderMap = (): JSX.Element | null => {
    const { block } = this.props;

    if (block.showMap && block.type === 'address') {
      const center = {
        lat: block.lat,
        lng: block.lon,
      };

      const zoom = pipe(
        O.fromNullable(block.zoomLevel),
        O.getOrElse(() => 10),
      );

      return pipe(
        getConfigValue('REACT_APP_GOOGLE_MAPS_KEY'),
        O.map(apiKey => (
          <Styled.Map>
            <GoogleMapReact bootstrapURLKeys={{ key: apiKey }} defaultCenter={center} defaultZoom={zoom} />
          </Styled.Map>
        )),
        O.toNullable,
      );
    }

    return null;
  };

  render(): JSX.Element {
    const { block } = this.props;

    return (
      <Styled.Container>
        <Styled.Content>
          <Title level="h4">{block.text}</Title>
          <Styled.Pin>
            <span />
          </Styled.Pin>
          <Text>{block.address}</Text>
          <Styled.RichText size="small" dangerouslySetInnerHTML={{ __html: block.richText }} />
        </Styled.Content>
        {this.renderMap()}
      </Styled.Container>
    );
  }
}
