import React, { useState } from 'react';

import * as O from 'fp-ts/lib/Option';

import { WithBlockProps, Image as ImageType } from '../../types/innoctopus/flow';

import * as Styled from './Image.styles';
import { withInnoctopusContext, WithInnoctopusContext } from '../../context';
import { Resource } from '../../types/innoctopus';
import { ImageOrPlaceholder } from '../../components/shared/image/ImageOrPlaceholder';
import Lightbox from 'react-image-lightbox';

import 'react-image-lightbox/style.css';
import { pipe } from 'fp-ts/lib/pipeable';

const Image = ({ block, page }: WithBlockProps<ImageType> & WithInnoctopusContext<any>) => {
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);

  const openPreview = () => setPreviewOpen(true);
  const closePreview = () => setPreviewOpen(false);

  const getResource = (id: string): O.Option<Resource> => {
    return O.fromNullable(page.resources[id]);
  };

  return pipe(
    getResource(block.image.resource),
    O.map(img => (
      <Styled.Container>
        <ImageOrPlaceholder resource={img} alt={block.caption} onClick={openPreview} />
        {previewOpen && <Lightbox mainSrc={img.url.original} onCloseRequest={closePreview} />}
      </Styled.Container>
    )),
    O.toNullable,
  );
};

export default withInnoctopusContext(Image);
