import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Gallery as GalleryType, WithBlockProps } from '../../types/innoctopus/flow';

import Glide from '@glidejs/glide';

import '@glidejs/glide/dist/css/glide.core.min.css';

import * as Styled from './Gallery.styles';
import { withInnoctopusContext, WithInnoctopusContext } from '../../context';
import { Resource } from '../../types/innoctopus';
import { notEmpty } from '../../helpers/predicats.helper';
import { ImageOrPlaceholder } from '../../components/shared/image/ImageOrPlaceholder';
import Lightbox from 'react-image-lightbox';

const Gallery = ({ page, block }: WithBlockProps<GalleryType> & WithInnoctopusContext<any>) => {
  const [open, setOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);

  const openGallery = (index: number) => () => {
    setOpen(true);
    setIndex(index);
  };

  const closeGallery = () => setOpen(false);

  const getResource = useCallback(
    (id: string): Resource | undefined => {
      return page.resources[id];
    },
    [page.resources],
  );

  const images = useMemo(
    () =>
      [
        ...block.images
          .map(image => {
            const resource = getResource(image.resource.resource);

            return resource
              ? {
                  order: image.order,
                  resource,
                }
              : null;
          })
          .filter(notEmpty),
      ].sort((a, b) => (a.order > b.order ? 1 : -1)),
    [block.images, getResource],
  );

  useEffect(() => {
    let glide: any = null;

    if (document.querySelector('.glide')) {
      glide = new Glide('.glide', {
        type: 'carousel',
        startAt: 0,
        perView: 3,
        focusAt: 'center',
        keyboard: false,
        autoplay: 10000,
        breakpoints: {
          1200: {
            perView: 2.5,
          },
          980: {
            perView: 2,
          },
          680: {
            perView: 1.2,
          },
        },
      });

      glide.mount();
    }

    return () => {
      if (glide) {
        glide.destroy();
      }
    };
  }, []);

  const goPrev = () => setIndex((index + images.length - 1) % images.length);
  const goNext = () => setIndex((index + 1) % images.length);

  return images.length > 0 ? (
    <Styled.Container>
      <Styled.Glide className="glide">
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {images.map(({ order, resource }, i) => (
              <li className="glide__slide" key={order} onClick={openGallery(i)}>
                <Styled.Slide>
                  <ImageOrPlaceholder resource={resource} size="xhdpi_2x" />
                </Styled.Slide>
              </li>
            ))}
          </ul>
        </div>
        <div className="glide__arrows" data-glide-el="controls">
          <Styled.LeftArrow className="glide__arrow glide__arrow--left" data-glide-dir="<" />
          <Styled.RightArrow className="glide__arrow glide__arrow--right" data-glide-dir=">" />
        </div>
      </Styled.Glide>
      {open && (
        <Lightbox
          mainSrc={images[index].resource.url.xhdpi_2x}
          nextSrc={images[(index + 1) % images.length].resource.url.xhdpi_2x}
          prevSrc={images[(index + images.length - 1) % images.length].resource.url.xhdpi_2x}
          onCloseRequest={closeGallery}
          onMovePrevRequest={goPrev}
          onMoveNextRequest={goNext}
        />
      )}
    </Styled.Container>
  ) : null;
};

export default withInnoctopusContext(Gallery);
