import styled from 'styled-components';

import { renderTypographyStyle } from '../../styles/utils.styles';

export const Container = styled.div`
  ${props => renderTypographyStyle(props.theme.typography.text.standard)};
  margin-bottom: 25px;

  strong {
    font-weight: ${props => props.theme.fonts.weights.bold};
  }

  em {
    font-style: italic;
  }

  ul {
    list-style: none;
    padding: 10px;

    li {
      position: relative;
      padding-left: 30px;

      &:before {
        content: '';
        position: absolute;
        left: 5px;
        top: 12px;
        display: block;
        width: 5px;
        height: 5px;
        background: ${props => props.theme.colors.tiertiary[700]};
        border-radius: 50%;
      }
    }
  }

  ol {
    padding: 10px;
    list-style: decimal inside;
  }
`;
